var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-progress-linear',{staticClass:"progress-bar",class:{
    'progress-red': _vm.red,
    'progress-green': _vm.green,
    'progress-grey': _vm.grey,
    'progress-blue': _vm.blue,
    'progress-purple': _vm.purple,
  },attrs:{"height":_vm.height,"value":_vm.percentFull,"rounded":true,"color":"none","background-color":"grey-fine"}})
}
var staticRenderFns = []

export { render, staticRenderFns }