import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { mdiSofaSingleOutline as sofaIcon } from '@mdi/js';
import TextButton from '@/components/business/TextButton.vue';
import { useSystemStore } from '@/stores/system';
var __default__ = defineComponent({
  components: {
    TextButton: TextButton
  },
  props: {
    addSeats: {
      // hide: don't show;
      // active: show, interactive;
      // disabled: show, not interative + optional tooltip
      type: String,
      default: 'hide'
    },
    removeSeats: {
      // hide: don't show;
      // active: show, interactive;
      // disabled: show, not interative + optional tooltip
      type: String,
      default: 'hide'
    },
    contact: {
      type: Boolean,
      default: false
    },
    addSeatsTooltip: {
      type: String,
      default: ''
    },
    removeSeatsTooltip: {
      type: String,
      default: ''
    },
    seats: {
      type: Object,
      default: function _default() {
        return {
          used: 1,
          total: 1
        };
      }
    }
  },
  setup: function setup() {
    var systemStore = useSystemStore();
    var successEmail = computed(function () {
      return systemStore.emails.success;
    });
    var fontSize = ref('40px');
    var onMutate = function onMutate(mutations) {
      var _textMutation$target$, _textMutation$target, _textMutation$target$2;
      var textMutation = mutations.find(function (mutation) {
        return mutation.type === 'characterData';
      });
      var digits = (_textMutation$target$ = textMutation === null || textMutation === void 0 ? void 0 : (_textMutation$target = textMutation.target) === null || _textMutation$target === void 0 ? void 0 : (_textMutation$target$2 = _textMutation$target.textContent) === null || _textMutation$target$2 === void 0 ? void 0 : _textMutation$target$2.replace(/\D/g, '')) !== null && _textMutation$target$ !== void 0 ? _textMutation$target$ : '';
      if (digits.length <= 2) {
        fontSize.value = '56px';
      } else if (digits.length <= 4) {
        fontSize.value = '48px';
      } else if (digits.length <= 6) {
        fontSize.value = '40px';
      } else if (digits.length <= 8) {
        fontSize.value = '32px';
      } else {
        fontSize.value = '28px';
      }
    };
    return {
      onMutate: onMutate,
      fontSize: fontSize,
      sofaIcon: sofaIcon,
      successEmail: successEmail
    };
  }
});
import { useCssVars as _useCssVars } from 'vue';
var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_vm, _setup) {
    return {
      "dabbb65e": _vm.fontSize
    };
  });
};
var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;