<template>
  <v-progress-linear
    class="progress-bar"
    :class="{
      'progress-red': red,
      'progress-green': green,
      'progress-grey': grey,
      'progress-blue': blue,
      'progress-purple': purple,
    }"
    :height="height"
    :value="percentFull"
    :rounded="true"
    color="none"
    background-color="grey-fine"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    color: {
      type: String as Vue.PropType<'blue' | 'red' | 'green' | 'purple' | 'grey'>,
      default: 'blue',
    },
    height: {
      type: Number,
      default: 10,
    },
    percentFull: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    blue(): boolean {
      return this.color === 'blue'
    },
    red(): boolean {
      return this.color === 'red'
    },
    green(): boolean {
      return this.color === 'green'
    },
    purple(): boolean {
      return this.color === 'purple'
    },
    grey(): boolean {
      return this.color === 'grey'
    },
  },
})
</script>

<style lang="sass">
.progress-bar
  .v-progress-linear__determinate
    border-radius: 4px
  &.progress-red
    .v-progress-linear__determinate
      background: linear-gradient(90deg, rgba($c-danger,1) 0%, rgba(#c60b3b,1) 100%) !important
  &.progress-grey
    .v-progress-linear__determinate
      background: $c-grey !important
  &.progress-blue
    .v-progress-linear__determinate
      background: linear-gradient(90deg, rgba($c-primary,1) 0%, rgba(#004ade,1) 100%) !important
  &.progress-green
    .v-progress-linear__determinate
      background: linear-gradient(90deg, rgba(#24B285,1) 0%, rgba(#009B64,1) 100%) !important
  &.progress-purple
    .v-progress-linear__determinate
      background: linear-gradient(90deg, rgba(#4900A6,1) 0%, rgba(#3F008C,1) 100%) !important
</style>
